import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import DigitalPlatformRestData from '../Data/DigitalPlatformRestData.json'

const AppDigitalPlatformRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .ContentSectionEmailDigitalPlatform {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .HeadingEmailDigitalPlatform {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaStyleDigitalPlatformOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaStyleDigitalPlatform {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
`

export const AppDigitalPlatformRest = () => {
  return (
    <AppDigitalPlatformRestWapper>
      <div>
        {DigitalPlatformRestData.map((data, index) => {
          return (
            <div className='MainEmailProcurementSection' key={index}>
              <div className='ContentSectionEmailDigitalPlatform'>
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle='HeadingEmailDigitalPlatform' />
                <ReUsePtag para={data.LeadParaTwo} paraStyle='LeadParaStyleDigitalPlatform' />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle='HeadingEmailDigitalPlatform' />
                <ReUsePtag para={data.LeadParaThree} paraStyle='LeadParaStyleDigitalPlatform' />
                <ReUsePtag para={data.LeadParaFive} paraStyle='LeadParaStyleDigitalPlatform' />
              </div>
            </div>
          )
        })}
      </div>
    </AppDigitalPlatformRestWapper>
  )
}
